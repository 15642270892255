<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" :maskClosable="false" @ok="toSubmit" okText="提交">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
            <a-form-model-item label="所属部门" prop="deptId" :autoLink="false" ref="test">
              <!-- <a-select placeholder="请选择" v-model="rowData.deptId" @change="onChange">
                <a-select-option :value="item.id" v-for="(item, index) in stationList" :key="index">{{item.name}}</a-select-option>
              </a-select> -->
              <a-cascader v-model="ids" placeholder="请选择" :options="stationList" :field-names="{ label: 'name', value: 'id', children: 'children' }" change-on-select @change="onChange" />
            </a-form-model-item>
            <a-form-model-item label="岗位名称" prop="name">
                <a-input v-model="rowData.name" placeholder="岗位名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="是否启用" prop="flagEnable">
              <a-switch v-model="rowData.flagEnable" checked-children="启用" un-checked-children="禁用" default-checked />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
                <a-input v-model="rowData.remark" placeholder="备注"></a-input>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { editFirmStation, selectByIdFirmStation, addFirmStation } from '../api/FirmStationApi'
import { treeData } from '@/utils/treeutil'

export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                deptId: [
                    { required: true, message: '请选择所属部门', trigger: 'change' }
                ],
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                flagEnable: [
                    { required: true, message: '请输入是否启用', trigger: 'blur' }
                ],
                remark: [
                    { required: true, message: '请输入备注', trigger: 'blur' }
                ],
            },
            stationList: [],
            ids: []
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = {}
            if(handle === 'add') {
              this.$set(this.rowData, 'flagEnable', true)
            }else if(handle === 'edit') {
              selectByIdFirmStation(row.id).then(res => {
                this.rowData = res.body
                this.ids = [this.rowData.firmId, this.rowData.deptId]
              })
            }
            this.getList()
        },
        getList() {
            this.axios.get('/api/firm/firm/firmDepartment/list?pageSize=9999&sourceType=1').then(res => {
                this.stationList = treeData(res.body)
            })
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                this.rowData.sourceType = 1
                const res = this.handle === 'add' ? await addFirmStation(this.rowData) : await editFirmStation(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        },
      onChange(val) {
        this.rowData.firmId = val.length > 0 ? val[0] : ''
        this.rowData.deptId = val.length > 0 ? val[1] : ''
      },
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
